<template>
  <div class="front-show" @mouseenter="stopTimer()" @mouseleave="restartTimer()">
    <div class="pic-container">
      <div
        v-for="(pic, contentIndex) in topShowList"
        :key="pic + 'pic_' + contentIndex"
        class="front-pic"
        :class="{ active: content.picIndex == contentIndex }"
        @click="onFrontPost(content.picIndex)"
      >
        <div class="pic-inner" :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"></div>
      </div>
    </div>
    <div class="nav-container">
      <div
        v-for="(pic, navIndex) in topShowList"
        :key="pic.id + '_nav' + navIndex"
        class="nav-pic"
        :class="{ active: content.picIndex == navIndex }"
        @click="scrollTo(navIndex)"
      ></div>
    </div>
  </div>
</template>

<script>
var timers = []
import RD from '@/api/RD'

export default {
  name: 'IndexTopShow',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data: function () {
    return {
      topShowList: [], // 控制显示前8个稿子
    }
  },
  created() {
    this.reload()
  },
  destroyed() {
    clearInterval(timers)
  },
  methods: {
    reload() {
      var thiz = this
      setTimeout(() => {
        thiz.content.list.forEach((i) => {
          if (i.frontPicId) {
            i.imgURL = RD.pic().id(i.frontPicId).tImage_URL()
          }
        })
        thiz.topShowList = thiz.content.list.splice(0, 8)
        // console.log('jkk', thiz.topShowList)
        timers = setInterval(() => {
          this.next()
        }, 3000)
      }, 1000)
    },
    next() {
      var thiz = this
      thiz.content.picIndex++
      if (thiz.content.picIndex >= thiz.topShowList.length) {
        thiz.content.picIndex = 0
      }
    },
    stopTimer() {
      clearInterval(timers)
    },
    restartTimer() {
      if (timers) {
        clearInterval(timers)
      }
      timers = setInterval(() => {
        this.next()
      }, 2000)
    },
    scrollTo(navIndex) {
      this.content.picIndex = navIndex
    },
    // 轮播的的点击, topshow模块调用了
    onFrontPost(contentIndex) {
      console.log('mx', this.topShowList, contentIndex)
      var postid = this.topShowList[contentIndex].postId
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postid },
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .front-show {
    height: 486px;
    position: relative;
    margin: 0px auto;
    left: 0px;
    right: 0px;

    .pic-container {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .front-pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 500ms ease-in-out;
        cursor: pointer;

        .pic-inner {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
        }

        .pic-inner-left {
          position: absolute;
          top: 0px;
          left: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .inner {
            position: absolute;
            top: 0px;
            right: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background: repeat 50% / contain;
          }
        }

        .pic-inner-right {
          position: absolute;
          top: 0px;
          right: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .inner {
            position: absolute;
            top: 0px;
            left: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background-color: black;
            background: repeat 50% / contain;
          }
        }
      }

      .front-pic.active {
        opacity: 1;
      }
    }

    .nav-container {
      position: absolute;
      bottom: 10px;
      right: 53%;
      transform: translate(20rem);
      width: 215px;
      height: 15px;
      display: flex;
      justify-content: space-between;

      .nav-pic {
        height: 12.5px;
        width: 12.5px;
        border-radius: 6.6px;
        border: 1px solid rgba(255, 255, 255, 0.6);
        margin-right: 8.5px;
        background: rgba(0, 0, 0, 0.5);
        transition: all 500ms ease-in-out;
        cursor: pointer;
      }

      .nav-pic.active {
        background-color: rgb(23, 47, 184);
        width: 42px;
      }
    }
  }
}
@media all and (min-width: 801px) {
  .front-show {
    height: 95rem;
    width: 100%;
    position: relative;
    margin: 0px auto;
    left: 0px;
    right: 0px;
    .pic-container {
      position: relative;
      max-width: 192rem;
      margin: 0 auto;
      height: 100%;
      overflow: hidden;
      .front-pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 500ms ease-in-out;
        cursor: pointer;

        .pic-inner {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
        }

        .pic-inner-left {
          position: absolute;
          top: 0px;
          left: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .inner {
            position: absolute;
            top: 0px;
            right: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background: repeat 50% / contain;
          }
        }

        .pic-inner-right {
          position: absolute;
          top: 0px;
          right: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .inner {
            position: absolute;
            top: 0px;
            left: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background-color: black;
            background: repeat 50% / contain;
          }
        }
      }

      .front-pic.active {
        opacity: 1;
      }
    }

    .nav-container {
      position: absolute;
      bottom: 1.25rem;
      right: 53%;
      transform: translate(20rem);
      width: 25rem;
      height: 2.5rem;
      display: flex;
      justify-content: space-between;

      .nav-pic {
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 0.66rem;
        border: 1px solid rgba(255, 255, 255, 0.6);
        margin-right: 0.85rem;
        background: rgba(0, 0, 0, 0.5);
        transition: all 500ms ease-in-out;
        cursor: pointer;
      }

      .nav-pic.active {
        background-color: rgb(23, 47, 184);
        width: 4.2rem;
      }
    }
  }
}
</style>

